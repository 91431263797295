@import "../common/variables";

.platform{
  padding: 100px 0px;
  .tap{
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #1143e4;
    margin-bottom: 50px;
    div{
      text-align: center;
      width: 100%;
      line-height: 50px;
      font-size: 18px;
      cursor: pointer;
    }
    div.on{
      background-color: #1143e4;
      color: #fff;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
    }
  }
  .title{
    span{
      color: #1143e4;
      background-color: #fff;
      font-family: "yg-jalnan";
      font-size: 54px;
    }
  }
  ul{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    text-align: center;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    li{
      width: 100%;
      border: 1px solid #1143e4;
      font-size: 22px;
      font-family: $fontBold;
      padding: 15px 0px;
      border-radius: 50px;
      background-color: #fff;
      line-height: 1.5;
      p{
        font-size: 16px;
        font-family: $mainFont;
        color: #666;
        line-height: 1.3;
      }
    }
    .iconimage01{
      position: absolute;
      top: -100px;
      left: -100px;
      z-index: -1;
    }
    .iconimage02{
      position: absolute;
      bottom: -50px;
      right: -50px;
      z-index: -1;
    }
  }

  form{
    width: 700px;
    margin: 0 auto;
    margin-top: 50px;
    div{
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      label{
        display: inline-flex;
        align-items: center;
        gap: 10px;
        padding: 0px 10px;
        span{
          font-size: 24px;
          font-family: $fontBold;
        }
        input{
          border: 1px solid #aaa;
          line-height: 40px;
          font-size: 16px;
          padding: 0px 10px;
          max-width: 300px;
          width: 100%;
          flex: 1;
        }
      }
    }
    input[type="submit"]{
      background-color: #1143e4;
      color: #fff;
      border: none;
      line-height: 50px;
      padding: 0px 50px;
      font-size: 24px;
      font-family: $fontBold;
      display: block;
      margin: 0 auto;
      margin-top: 30px;
    }
  }
}

@media (max-width:1000px) {
  .platform ul{
    grid-template-columns: 1fr;
  }
}

@media (max-width:800px) {
  .platform form{
    width: 100%;
    margin-top: 30px;
  }
  .platform form div{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 10px;
  }
  .platform form div label span{
    width: 60px;
    font-size: 20px;
  }
}

@media (max-width:600px) {
  .platform ul li{
    font-size: 20px;
  }
}

@media (max-width:499px) {
  .platform .tap{
    div{
      line-height: 40px;
      font-size: 13px;
    }
  }
  .platform ul li{
    font-size: 15px;
    padding: 20px 0px;
    p{
      font-size: 12px;
    }
  }
  .platform form input[type="submit"]{
    padding: 0px 30px;
    font-size: 20px;
  }
}

@media (max-width:400px) {
  .platform ul li{
    font-size: 15px;
    padding: 20px 0px;
    p{
      font-size: 12px;
    }
  }
  
  .platform form input[type="submit"]{
    padding: 0px 30px;
    font-size: 16px;
  }
}

@media (max-width:350px) {
  .platform ul li{
    font-size: 13px;
    padding: 20px 0px;
    img{
      height: 20px !important;
    }
    p{
      font-size: 10px;
    }
  }
}