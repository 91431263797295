@import 'variables';
@import 'font';

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  vertical-align: top;
  font-size: 14px;
  line-height: 1;
  outline: 0px;
  /*outline: 1px solid black;*/
}

body {
  // text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

ul,
ol {
  list-style: none;
  font-size: 0px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

ul li,
ol li {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: inherit;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

a {
  display: inline-block;
  text-decoration: none;
  color: black;
}

.wrap {
  // display: inline-block;
  margin: 0px auto;
  width: 1200px;
}

.pc {
  display: inline-block !important;
}

.mobile {
  display: none !important;
}

.flexBox {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
table {
  border-collapse: collapse;
}

caption {
  display: none;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='search'],
input[type='image'],
input[type='tel'],
input[type='submit'],
button,
select,
textarea {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  background: none;
  border: 0;
}

@media only screen and (min-width: 100px) and (max-width: 1300px) {
}
@media only screen and (min-width: 100px) and (max-width: 1200px) {
  .wrap {
    width: 100%;
    padding: 0px 5px;
  }

  .pc {
    display: none !important;
  }

  .mobile {
    display: inherit !important;
  }
}

@media only screen and (min-width: 100px) and (max-width: 1000px) {
}

@media only screen and (min-width: 100px) and (max-width: 800px) {
}

@media only screen and (min-width: 100px) and (max-width: 600px) {
}

html {
  font-family: $mainFont;
}

header {
  background-color: #fff;
  border-bottom: 1px solid #eee;
  #logo {
    img {
      width: 150px;
    }
  }

  .menu {
    ul {
      display: inline-block;
      li {
        a {
          line-height: 90px;
          font-size: 18px;
          padding: 0px 6px;
          margin: 0px 5px;
          font-weight: 500;
        }
      }
    }
  }

  .sns {
    a {
      padding: 0px 5px;
    }
  }

  .mobile {
    .button {
      i {
        font-size: 24px;
        cursor: pointer;
        color: #9e9e9e;
      }
    }
    .bg {
      background-color: #00000088;
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s;
    }
    .bg.on {
      visibility: visible;
      opacity: 1;
    }
    .menuBox {
      position: fixed;
      top: 0;
      left: 0;
      width: 250px;
      height: 100vh;
      max-width: 0px;
      overflow: hidden;
      transition: max-width 0.5s;
      background-color: #373737;
      z-index: 11;
      text-align: center;
      .logo {
        width: 150px;
        margin-top: 30px;
      }

      ul {
        li {
          width: 100%;
          a {
            width: 100%;
            line-height: 60px;
            font-size: 18px;
            white-space: nowrap;
            color: #fff;
            border-bottom: 1px solid #484848;
            &:first-child {
              border-top: 1px solid #484848;
            }
          }
        }
      }

      .sns {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        display: flex;
        justify-content: center;
        a {
          img {
            width: 100%;
            opacity: 0.5;
          }
        }
      }
    }
    .menuBox.on {
      max-width: 250px;
    }
  }
}

footer {
  background-color: #373737;
  padding-bottom: 50px;
  overflow: hidden;
  .download {
    position: relative;
    margin-bottom: 50px;
    & > img {
      width: 100%;
      height: 165px;
      max-width: 100%;
    }
    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 800px;
      width: 100%;
      > div {
        p {
          color: #fff;
          font-size: 28px;
          margin-bottom: 10px;
          font-family: $fontBold;
          > span {
            font-size: 28px;
            color: #aafaff;
          }
        }
        > .sns {
          a {
            margin: 0px 5px;
            img {
              width: 24px;
            }
          }
        }
      }
    }
    ul {
      li {
        margin: 0px 10px;
      }
    }
  }
  .bottom {
    .wrap {
      > div {
        display: flex;
        gap: 100px;
        #logo {
          img {
            width: 150px;
          }
        }

        > div {
          color: #fff;
          > p {
            line-height: 1.4;
            font-size: 16px;
            &:nth-child(2) {
              font-size: 14px;
              color: #999999;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  header {
    padding: 10px 10px;
  }
  header #logo img {
    width: 110px;
  }
}

@media (max-width: 800px) {
  footer .download .content {
    flex-direction: column;
    text-align: center;
  }
  footer .download .content .sns {
    margin: 5px 0px 10px;
  }
  footer .bottom .wrap > div {
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }
}

@media (max-width: 500px) {
  footer .download .content > div p {
    font-size: 24px;
  }
  footer .download .content > div p > span {
    font-size: 24px;
  }
  footer .download .content ul li img {
    width: 140px;
  }
  footer .bottom .wrap > div {
    gap: 15px;
  }
  footer .bottom .wrap > div #logo img {
    width: 120px;
  }
  footer .bottom .wrap > div > div > p {
    font-size: 14px;
    letter-spacing: -1px;
  }
}

@media (max-width: 400px) {
  footer .bottom .wrap > div > div > p {
    font-size: 12px;
  }
  footer .bottom .wrap > div > div > p:nth-child(2) {
    margin-top: 20px;
  }
}

@media (max-width: 340px) {
  footer .bottom .wrap > div > div > p {
    font-size: 10px;
  }
}
