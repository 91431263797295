@import '../common/variables';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

iframe#webpack-dev-server-client-overlay{display:none!important} // ResizeObserver loop limit exceeded

.container {
  position: relative;
  font-family: $slideFont;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  color: #333;
  background-color: #B7E3FF;

  .contentWrap {
    position: relative;
    width: 100%;

    .images {
      width: 100%;
      height: auto;
      max-height: 100vh;
      margin: 0 auto;
      object-fit: initial;
      box-sizing: border-box;
      padding: 0 7.2vw;
    }

    .conceptLearningWrap1 {
      position: absolute;
      left: 26%;
      width: 15vw;
      top: 28.1%;
      height: 30.5%;
      overflow: hidden;
      border: 1px solid #416DC7;
    }

    .conceptLearningWrap2 {
      position: absolute;
      left: 42.4%;
      width: 15.1vw;
      top: 28.1%;
      height: 30.5%;
      overflow: hidden;
      border: 1px solid #416DC7;
    }

    .conceptLearningWrap3 {
      position: absolute;
      left: 75.4%;
      width: 15.1vw;
      top: 27.9%;
      height: 30.3%;
      overflow: hidden;
      border: 1px solid #416DC7;
    }

    .aiTutorVideoWrap1 {
      position: absolute;
      left: 46.4%;
      width: 13.3vw;
      top: 30%;
      height: 54%;
      overflow: hidden;
      border: 1px solid #6D9ED0;
    }

    .aiTutorVideoWrap2 {
      position: absolute;
      left: 76%;
      width: 13.1vw;
      top: 30%;
      height: 54%;
      overflow: hidden;
      border: 1px solid #6D9ED0;
    }

    .video {
      position: relative;
      width: 100%;
      height: auto;
    }

    .aiTutorVideo {
      top: -24px;
    }

    .conceptLearningVideo {
      top: -60%;
    }

    .conceptContainer {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.6vw;
      top: 60.4%;
      width: 100%;
      height: 12%;
      padding: 0 9.5%;

      .conceptStepBox {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 1vw;
        border: 1px solid #548ED4;
        background-color: #EBF1DE;

        .conceptStepTitle {
          font-size: 1.7vw;
          color: #080908;
        }

        .conceptStepContent {
          font-size: 1.7vw;
          color: #3B6DF1;
        }
      }
    }

    .flowChartContainer1 {
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 2.6vw;
      top: 60%;
      width: 100%;
      height: 18.6%;
      padding: 0 18.6% 0 16%;

      .flowChartBox {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 12%;
        height: 38%;
        border: 1px solid #3568F1;
        background-color: #DBEDF4;

        .flowChartTitle {
          font-size: 1.25vw;
          white-space: pre-wrap;
          text-align: center;
          color: #080908;
        }
      }
    }

    .flowChartContainer2 {
      position: absolute;
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 2.6vw;
      top: 72%;
      width: 100%;
      height: 18.6%;
      padding: 0 18.6% 0 16%;

      .flowChartBox {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 12%;
        height: 38%;
        border: 1px solid #3568F1;
        background-color: #DBEDF4;

        .flowChartTitle {
          font-size: 1.25vw;
          white-space: pre-wrap;
          text-align: center;
          color: #080908;
        }
      }
    }
  }
}

.slick-prev {
  left: 15px;
  width: 40px;
  z-index: 1;

  &:before {
    font-size: 40px;
    color: #000000;
  }
}

.slick-next {
  right: 15px;
  width: 40px;
  z-index: 1;

  &:before {
    font-size: 40px;
    color: #000000;
  }
}

.slick-dots {
  bottom: 10px;

  li {
    width: auto;

    .name {
      font-size: 12px;
      border: 1px solid #5f5f5f;
      border-radius: 12px;
      padding: 4px 12px;
      letter-spacing: -.55px;
      background-color: #FFFFFF;
    }

    &.slick-active {
      .name {
        background-color: #002060;
        color: #FFFFFF;
      }
    }
  }
}

// media 1500px 이하
@media (max-width: 1570px) {
  .slick-prev, .slick-next {
    &:before {
      color: #333333;
    }
  }
}
