@import "../common/variables";

.App > div{
  overflow: hidden;
}

.pcimg{}
.moimg{
  display: none;
}

.blue{
  background-color: #f6fbff;
}
.gray{
  background-color: #f5f5f5;
}

.title{
  text-align: center;
  font-size: 28px;
  font-family: $fontBold;
  line-height: 1.2;
  margin-bottom: 50px;
  span{
    color: #fff;
    font-size: 28px;
    line-height: 1.2;
    padding: 0px 5px;
    border-radius: 7px;
    background-color: #1143e4;
  }
  > p{
    font-size: 18px;
    color: #666666;
    font-family: $mainFont;
    margin-top: 10px;
  }
}
.title.font{
  font-family: $fontLight;
  letter-spacing: -1px;
  span{
    font-family: $subFont;
    background-color: transparent;
    color: #1143e4;
    font-size: 34px;
  }
}
.none{
  animation-name: ani;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes ani{
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(0.9);
  }
  100%{
    transform: scale(1);
  }
}

@media (max-width:1200px) {
  .none{
    display: none;
  }
}
@media (max-width:600px) {
  .pcimg{
    display: none;
  }
  .moimg{
    display: block;
  }
  .title{
    font-size: 22px !important;
    margin-bottom: 20px !important;
  }
  .title span{
    font-size: 22px !important;
  }
  .title > p{
    font-size: 14px !important;
  }
}

@media (max-width:400px) {
  .title > p{
    font-size: 12px !important;
    letter-spacing: -1px !important;
  }
}

@media (max-width:370px) {
  .title{
    letter-spacing: -2px !important;
  }
  .title span{
    letter-spacing: -2px !important;
  }
}
@media (max-width:330px) {
  .title{
    font-size: 18px !important;
  }
  .title span{
    font-size: 18px !important;
  }
}