@import "../common/variables";

.tutor{
  text-align: center;
  > div{
    padding: 100px 0px;
    .book{
      width: 1000px;
      margin: 0 auto;
      padding: 60px 0px;
      text-align: center;
      background-image: url("../../../public/image/subPage/tutor/section01_note(pc).png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      .play{
        position: relative;
        img{
          max-width: 700px;
          width: 100%;
        }
        video{
          position: absolute;
          top: 50%;
          left: 55%;
          transform: translateY(-50%);
          width: 150px;
        }
      }
      .grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        margin: 20px auto 0px;
        max-width: 700px;
        img{
          width: 100%;
        }
      }
    }
    .position{
      position: relative;
      img{
        position: relative;
        z-index: 10;
      }
      .character01{
        position: absolute;
        bottom: -50px;
        right: -0px;
      }
      .character02{
        position: absolute;
        bottom: -30px;
        left: 0px;
      }
      .iconimage01{
        position: absolute;
        left: 0px;
        top: -80px;
        z-index: 1;
      }
      .iconimage02{
        position: absolute;
        right: 40px;
        top: -40px;
        z-index: 1;
      }
    }
  }
}

@media (max-width:1200px) {
  .tutor > div {
    padding: 100px 70px;
  }
  .tutor > div .book{
    background-image: url("../../../public/image/subPage/tutor/section01_note(mobile).png");
    width: 100%;
    height: 900px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .tutor > div .book .play img,
  .tutor > div .book .grid{
    width: 700px;
  }
  .tutor > div .position img:nth-child(1){
    width: 100%;
  }
  .tutor > div .position .character01{
    width: 100px;
    right: -60px;
    bottom: -10px;
  }
  .tutor > div .position .character02{
    width: 100px;
    left: -50px;
    bottom: -10px;
  }
}

@media (max-width:900px) {
  .tutor > div .book{
    width: 100%;
    height: 700px;
  }
  .tutor > div .book .play img,
  .tutor > div .book .grid{
    width: 550px;
  }
  .tutor > div .book .play video{
    width: 120px;
  }
}
@media (max-width:700px) {
  .tutor > div {
    padding: 100px 50px;
  }
  #scroll01{
    padding: 100px 20px;
  }
  .tutor > div .book{
    width: 100%;
    height: 500px;
  }
  .tutor > div .book .play img,
  .tutor > div .book .grid{
    width: 400px;
  }
  .tutor > div .book .play video{
    width: 90px;
  }
  .tutor > div .position img:nth-child(1){
    width: 100%;
  }
  .tutor > div .position .character01{
    right: -50px;
  }
  .tutor > div .position .character02{
    left: -40px;
  }
}

@media (max-width:499px) {
  .tutor > div {
    padding: 50px 30px;
  }
  .tutor > div .book{
    width: 100%;
    height: 400px;
  }
  .tutor > div .book .play img,
  .tutor > div .book .grid{
    width: 320px;
  }
  .tutor > div .book .play video{
    width: 70px;
  }
  .tutor > div .position .character01{
    width: 60px;
    right: -30px;
  }
  .tutor > div .position .character02{
    width: 60px;
    left: -20px;
  }
}

@media (max-width:380px) {
  #scroll01{
    padding: 30px 20px;
  }
  .tutor > div .book{
    width: 100%;
    height: 300px;
  }
  .tutor > div .book .play img,
  .tutor > div .book .grid{
    width: 250px;
  }
  .tutor > div .book .play video{
    width: 50px;
  }
}
#scroll01{
  .book,
  .position{
    transform: scale(0.5);
    opacity: 0;
    transition: transform 1s , opacity 1s;
  }
}
#scroll01.on{
  .book,
  .position{
    transform: scale(1);
    opacity: 1;
  }
}
#scroll02{
  .position{
    transform: scale(0.5);
    opacity: 0;
    transition: transform 1s , opacity 1s;
  }
}
#scroll02.on{
  .position{
    transform: scale(1);
    opacity: 1;
  }
}
