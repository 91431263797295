@import '../common/variables';

.academy {
  text-align: center;
  > div {
    padding: 100px 0px;
    ul {
      display: inline-flex;
      align-items: center;
      gap: 50px;
      li {
        position: relative;
        > div {
          margin-top: 30px;
        }
      }
    }
    p {
      max-width: 1078px;
      width: 100%;
      margin: 0 auto;
      color: #fff;
      background-color: #1143e4;
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 10px;
    }
    ol {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      li {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        font-weight: 400;
        position: relative;
        img {
          &:first-child {
            margin-bottom: 5px;
          }
        }
        .character01 {
          position: absolute;
          bottom: 20px;
          right: -150px;
        }
        .character02 {
          position: absolute;
          bottom: 80px;
          left: -120px;
        }
        .character03 {
          position: absolute;
          bottom: 80px;
          right: -110px;
        }
      }
    }
    .iconimage01 {
      position: absolute;
      top: -100px;
      left: -100px;
      z-index: -1;
    }
    .iconimage02 {
      position: absolute;
      top: -50px;
      left: -100px;
      z-index: -1;
    }
    .iconimage03 {
      position: absolute;
      top: -50px;
      right: -50px;
    }
  }
}

@media (max-width: 1200px) {
  .academy > div ol li img:nth-child(1) {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .academy > div ul {
    flex-direction: column;
    gap: 30px;
  }
  .academy > div ul li:nth-child(2) > img {
    width: 100%;
  }
  .academy > div ul li > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .academy > div ul li > div > img {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .academy > div ol:not(.grid) {
    padding: 0px 50px;
  }
  .academy > div.on ol li .character01 {
    width: 100px;
    right: -50px;
    right: 50px !important;
  }
  .academy > div.on ol li .character02 {
    width: 50px;
    left: -45px;
  }
  .academy > div.on ol li .character03 {
    width: 50px;
    right: -45px;
  }
}

@media (max-width: 600px) {
  .academy > div ul li > div {
    position: relative;
    min-height: 410px;
  }
  .academy > div ul li > div > img:nth-child(1) {
    position: absolute;
    width: 150px;
    bottom: 30px;
    left: 30px;
    z-index: 1;
  }
  .academy > div ul li > div > img:nth-child(2) {
    position: absolute;
    width: 150px;
    bottom: 30px;
    right: 30px;
    z-index: 1;
  }
  .academy > div ul li > div > img:nth-child(3) {
    position: absolute;
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
  }
  .academy > div p {
    font-size: 14px;
    padding: 15px 0px;
    letter-spacing: -1px;
  }
  .academy > div ol li {
    font-size: 14px;
  }
  .academy > div ol.grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .academy > div ol:not(.grid) {
    padding: 0px 20px;
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
  }
  .academy > div ol:not(.grid) li img {
    width: 150px;
  }
  .academy > div ol:not(.grid) li:nth-child(3) {
    grid-column: 1/3;
    margin-top: 20px;
    img {
      &:nth-child(1) {
        margin: 0 auto;
      }
    }
  }
  .academy > div.on ol:not(.grid) li .character01 {
    width: 70px;
    bottom: 40px;
    right: 50px !important;
  }
  .academy > div.on ol:not(.grid) li .character02 {
    width: 40px;
    bottom: 50px;
    left: -30px;
  }
  .academy > div.on ol:not(.grid) li .character03 {
    width: 70px;
    bottom: 40px;
    right: 0px !important;
  }
  .academy > div.on ol:not(.grid) li .character03.moimg {
    width: 70px;
    top: 40px;
    left: 0px !important;
  }
}

@media (max-width: 499px) {
  .academy > div {
    padding: 50px 0px;
  }
  .academy > div ul li > div > img:nth-child(1) {
    width: 120px;
    left: 0px;
  }
  .academy > div ul li > div > img:nth-child(2) {
    width: 120px;
    right: 0px;
  }
  .academy > div ol li {
    font-size: 12px;
    letter-spacing: -1px;
  }
}
@media (max-width: 370px) {
  .academy > div p {
    font-size: 12px;
  }
  .academy > div ul li > div {
    min-height: 308px;
  }
  .academy > div ul li > div > img:nth-child(1) {
    width: 100px;
    left: -10px;
  }
  .academy > div ul li > div > img:nth-child(2) {
    width: 100px;
    right: -10px;
  }
  .academy > div ul li > div > img:nth-child(3) {
    width: 150px;
  }

  .academy > div ol:not(.grid) li img {
    width: 120px;
  }
  .academy > div.on ol:not(.grid) li .character03 {
    width: 50px;
    bottom: 40px;
    right: 20px !important;
  }
  .academy > div.on ol:not(.grid) li .character03.moimg {
    width: 50px;
    top: 40px;
    left: 20px !important;
  }
}

#scroll01 {
  ul {
    li {
      position: relative;
      top: 100px;
      opacity: 0;
      @for $i from 1 to 3 {
        &:nth-child(#{$i}) {
          transition: opacity 0.7s $i * 0.3s, top 0.7s $i * 0.3s;
        }
      }
    }
  }
}
#scroll01.on {
  ul {
    li {
      top: 0px;
      opacity: 1;
    }
  }
}
#scroll02 {
  p {
    opacity: 0;
    transition: opacity 0.7s;
  }
  ol {
    li {
      position: relative;
      transition: left 1s 0.5s, right 1s 0.5s, opacity 0.7s 0.5s;
      &:nth-child(1) {
        left: -200px;
        opacity: 0;
      }
      &:nth-child(2) {
        right: -200px;
        opacity: 0;
      }
    }
  }
}
#scroll02.on {
  p {
    opacity: 1;
  }
  ol {
    li {
      position: relative;
      &:nth-child(1) {
        left: 0px;
        opacity: 1;
      }
      &:nth-child(2) {
        right: 0px;
        opacity: 1;
      }
    }
  }
}
#scroll03 {
  ol {
    li {
      position: relative;
      top: 100px;
      opacity: 0;
      @for $i from 1 to 4 {
        &:nth-child(#{$i}) {
          transition: top 0.7s $i * 0.3s, opacity 0.7s $i * 0.3s;
        }
      }
      .character01 {
        right: -200px;
        opacity: 0;
        transition: right 1s 1.6s, opacity 1s 1.6s;
      }
    }
  }
}
#scroll03.on {
  ol {
    li {
      opacity: 1;
      top: 0px;
      .character01 {
        right: -150px;
        opacity: 1;
      }
    }
  }
}

#scroll04 {
  ol {
    li {
      position: relative;
      top: 100px;
      opacity: 0;
      @for $i from 1 to 4 {
        &:nth-child(#{$i}) {
          transition: top 0.7s $i * 0.3s, opacity 0.7s $i * 0.3s;
        }
      }
      .character02 {
        left: -200px;
        opacity: 0;
        transition: left 1s 1.6s, opacity 1s 1.6s;
      }
      .character03 {
        right: -200px;
        opacity: 0;
        transition: right 1s 1.6s, opacity 1s 1.6s;
      }
    }
  }
}
#scroll04.on {
  ol {
    li {
      opacity: 1;
      top: 0px;
    }
    .character02 {
      left: -110px;
      opacity: 1;
    }
    .character03 {
      right: -110px;
      opacity: 1;
    }
  }
}
.academy.pdf iframe {
  width: 100%;
  height: calc(100vh - 91px - 80px);
  @media (max-width: 1200px) {
    height: calc(100vh - 69px - 66px);
  }
}
.academyTabs {
  display: flex;
  justify-content: center;
  align-items: center;
  .wrap {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px 0;
    @media (max-width: 1200px) {
      padding: 0;
      gap: 0;
      border-bottom: 1px solid #1143e4;
    }
    .tab {
      padding: 10px 20px;
      flex: 1;
      border: 1px solid #1143e4;
      font-size: 18px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media (max-width: 1200px) {
        font-size: 16px;
        padding: 16px;
        border-radius: 0px;
        border: none;
      }
      &.active {
        background-color: #1143e4;
        color: #fff;
      }
    }
  }
}
.dummy {
  width: 100%;
  min-height: 100vh;
}

.academy .images{
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  img{
    width: 100%;
    max-width: 1200px;
    height: auto;
  }
}