@import "./common/variables";

.clip-pc {
  position: relative;
  .video-clip {
    position: absolute;
    z-index: 1;
    top: 103px;
    left: 39px;
    width: 308px;
    height: 547px;
    object-fit: cover;
    object-position: top center;
  }
  .pencil {
    z-index: 2;
  }
}
.mo-phone {
  position: relative;
  width: 100%;
  height: 100%;
}
.clip-mo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .video-clip {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-52%, -52%);
    width: auto;
    height: 71%;
    aspect-ratio: 9/16;
    object-fit: cover;
    object-position: top center;
  }
  .pencil {
    z-index: 2;
  }
  img {
    width: auto;
    height: 100%;
  }
}
.pop {
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 10;
  width: 400px;
  img {
    width: 100%;
  }
  form {
    background-color: #3372db;
    padding: 15px 30px;
    text-align: center;
    div {
      display: grid;
      grid-template-columns: 0.7fr 1fr;
      grid-gap: 10px;
      input {
        border: 3px solid #002494;
        line-height: 30px;
        border-radius: 50px;
        padding: 0px 15px;
        width: 100%;
      }
    }

    button {
      background-color: #002494;
      color: #fff;
      font-family: "yg-jalnan";
      padding: 10px 50px 12px;
      font-size: 20px;
      border-radius: 7px;
      margin-top: 20px;
    }
  }
  i {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 50px;
    color: #000;
    cursor: pointer;
  }
}

.pop2 {
  position: fixed;
  top: 30px;
  left: 450px;
  z-index: 10;
  width: 400px;
  img {
    width: 100%;
  }
  form {
    background-color: rgb(187, 233, 255);
    padding: 15px 30px;
    text-align: center;
    div {
      display: grid;
      grid-template-columns: 0.7fr 1fr;
      grid-gap: 10px;
      input {
        border: 3px solid #002494;
        line-height: 30px;
        border-radius: 50px;
        padding: 0px 15px;
        width: 100%;
      }
    }

    button {
      background-color: #002494;
      color: #fff;
      font-family: "yg-jalnan";
      padding: 10px 50px 12px;
      font-size: 20px;
      border-radius: 7px;
      margin-top: 20px;
    }
  }
  i {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 50px;
    color: #000;
    cursor: pointer;
  }
}

.banner {
  position: relative;
  overflow: hidden;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    width: 1000px;
    z-index: 2;
    > div:not(.slide) {
      flex: 1;
      h3 {
        font-family: $subFont;
        font-size: 50px;
        line-height: 1.2;
        color: #ffe95c;
      }
      h4 {
        font-family: $subFont;
        font-size: 24px;
        color: #fff;
        line-height: 1.2;
      }
      .p {
        color: #fff;
        font-size: 21px;
        margin: 50px 0px 100px;
      }
      .camera {
        position: relative;
        img {
          position: absolute;
          left: 100px;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        p {
          display: inline-block;
          background-color: #fff;
          padding: 20px 50px 20px 210px;
          font-size: 22px;
          border-radius: 40px;
          line-height: 1.2;
          span {
            color: #1143e4;
            font-weight: bold;
            font-size: 22px;
            line-height: 1.2;
          }
          b {
            font-size: 22px;
            line-height: 1.2;
          }
        }
      }
    }
  }
  .slide {
    position: relative;
    width: 396px;
    height: 766px;
    img:not(:last-child) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 1.5s;
    }
    img.on {
      opacity: 1;
    }
    img:last-child {
      position: absolute;
      bottom: 100px;
      right: -80px;
      animation-name: pen;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
  }
  .moContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    padding: 0px 50px;
    z-index: 2;
    display: none;
    h3 {
      font-family: $subFont;
      color: #ffe95c;
      font-size: 40px;
      line-height: 1.2;
    }
    h4 {
      font-family: $subFont;
      color: #fff;
      font-size: 30px;
      line-height: 1.2;
      margin-bottom: 20px;
    }
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      li:not(.slides, .mo-phone) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .p {
          color: #fff;
          line-height: 1.2;
          font-size: 24px;
        }
        .pen {
          width: 100px;
          animation-name: pens;
          animation-duration: 3s;
          animation-iteration-count: infinite;
        }
        p:not(.p) {
          font-size: 18px;
          background-color: #fff;
          padding: 20px 40px;
          border-radius: 100px;
          display: inline-block;
          line-height: 1.2;
          white-space: nowrap;
          span {
            color: #1143e4;
            font-size: 18px;
            line-height: 1.2;
          }
          b {
            color: #000;
            font-size: 18px;
            line-height: 1.2;
          }
        }
        img:last-child {
          width: 200px;
        }
      }
      li.slides {
        width: 100%;
        height: 100%;
        position: relative;
        img {
          // position: absolute;
          // max-width: 100%;
          // max-height: 100%;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
          // opacity: 0;
          // transition: opacity 1.5s;
        }
        img.on {
          opacity: 1;
        }
      }
    }
  }
  .imageIcon {
    img {
      position: absolute;
      z-index: 1;
      opacity: 0.7;
      width: 260px;
      &:nth-child(1) {
        top: 55%;
        left: -2%;
        animation-name: imageIcon01;
        animation-duration: 3s;
        animation-iteration-count: infinite;
      }
      &:nth-child(2) {
        top: 20%;
        left: 92%;
        animation-name: imageIcon02;
        animation-duration: 5s;
        animation-iteration-count: infinite;
      }
    }
  }
}
@keyframes imageIcon01 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes imageIcon02 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pen {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes pens {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@media (max-width: 800px) {
  .banner > img {
    width: 1600px;
  }
  .banner .content {
    display: none;
  }
  .banner .moContent {
    display: block;
  }
  .banner .imageIcon img {
    width: 200px;
  }
  .banner .imageIcon img:nth-child(1) {
    top: 7%;
    left: -10%;
  }
  .banner .imageIcon img:nth-child(2) {
    top: 7%;
    left: 85%;
  }
}

@media (max-width: 700px) {
  .banner .moContent {
    padding: 0px 0px;
  }
}
@media (max-width: 650px) {
}

@media (max-width: 600px) {
  .banner .moContent ul li:not(.slides) p:not(.p) {
    font-size: 16px;
  }
  .banner .moContent ul li:not(.slides) p:not(.p) b {
    font-size: 16px;
  }
  .banner .moContent ul li:not(.slides) p:not(.p) span {
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .pop {
    width: unset;
    height: 100%;
    left: 10px;
  }
  .pop2 {
    width: unset;
    height: 100%;
    left: 10px;
  }
  .pop img {
    height: 70%;
  }
  .pop2 img {
    height: 70%;
  }
  .banner > img {
    width: 1200px;
  }
  .banner .moContent h3 {
    font-size: 28px;
  }
  .banner .moContent h4 {
    font-size: 18px;
  }
  .banner .moContent ul li:not(.slides) .p {
    font-size: 18px;
  }
  .banner .moContent ul li:not(.slides) .pen {
    width: 80px;
    margin: 10px 0px;
  }
  .banner .moContent ul li:not(.slides) p:not(.p) {
    padding: 10px 20px;
    font-size: 14px;
  }
  .banner .moContent ul li:not(.slides) p:not(.p) b {
    font-size: 14px;
  }
  .banner .moContent ul li:not(.slides) p:not(.p) span {
    font-size: 14px;
  }
  .banner .moContent ul li:not(.slides, .mo-phone) img:last-child {
    width: 120px;
  }
  .banner .imageIcon img {
    width: 120px;
  }
}

@media (max-width: 400px) {
  .banner > img {
    width: 1000px;
  }
  .banner .moContent h3 {
    font-size: 26px;
  }
  .banner .moContent h4 {
    font-size: 16px;
  }
  .banner .moContent ul li:not(.slides) .p {
    font-size: 14px;
  }
  .banner .moContent ul li:not(.slides) .pen {
    width: 50px;
  }
  .banner .moContent ul li:not(.slides) p:not(.p) {
    font-size: 12px;
    padding: 5px 15px;
  }
  .banner .moContent ul li:not(.slides) p:not(.p) b {
    font-size: 12px;
  }
  .banner .moContent ul li:not(.slides) p:not(.p) span {
    font-size: 12px;
  }
  .banner .moContent ul li:not(.slides) img:last-child {
    width: 90px;
  }
  .banner .imageIcon img {
    width: 100px;
  }
}

@media (max-width: 400px) {
  .pop form button,
  .pop2 form button {
    font-size: 16px;
  }
}
@media (max-width: 350px) {
  .pop form button,
  .pop2 form button {
    font-size: 14px;
    white-space: nowrap;
  }
}

.notice-button {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #ff4500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.notice-button:hover {
  background-color: #ff6347;
}

.attention-animation {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 69, 0, 0.8);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(255, 69, 0, 1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 69, 0, 0.8);
  }
}
