@import "../common/variables";

.promotion{
  .section{
    padding: 100px 0px;
    text-align: center;
    p{
      font-size: 30px;
      font-weight: 500;
      margin: 20px 0px 50px;
    }
    iframe{
      width: 800px;
      height:400px;
    }
  }
}

@media (max-width:900px) {
  .promotion .section p{
    font-size: 20px;
    margin: 15px 0px 30px;
  }
  .promotion .section iframe{
    width: 550px;
    height: 300px;
  }
}

@media (max-width:600px) {
  .promotion .section{
    padding: 70px 0px;
  }
  .promotion .section iframe{
    width: 450px;
    height: 250px;
  }
}

@media (max-width:500px) {
  .promotion .section{
    padding: 50px 0px;
  }
  .promotion .section img{
    width: 40px;
  }
  .promotion .section p{
    font-size: 16px;
    margin: 10px 0px 20px;
  }
  .promotion .section iframe{
    width: 320px;
    height: 180px;
  }
}

@media (max-width:320px) {
  .promotion .section p{
    font-size: 14px;
    margin: 10px 0px 20px;
  }
  .promotion .section iframe{
    width: 300px;
    height: 180px;
  }
}