.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 900px;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.modal-content img {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
  margin-bottom: 20px;
}

.modal-close {
  position: absolute;
  right: 24px;
  top: 24px;
  margin-top: 10px;
  padding: 5px 10px;
  background: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-close:hover {
  background: #555;
}
