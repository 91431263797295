@import '../common/variables';
.inner-box {
  position: relative;
}
.ai-tutor {
  background-color: #ebf6f7;
}
.ai-tutor02 {
  background-color: #fff;
}
.left-box {
  position: relative;
  .iconimage01 {
    position: absolute;
    left: 5%;
    top: 0;
    z-index: 0;
  }
  @media screen and (min-width: 701px) {
    justify-content: flex-end;
  }
}
.right-box {
  position: relative;
  .iconimage02 {
    position: absolute;
    right: 5%;
    bottom: 5%;
    z-index: 0;
  }
  @media screen and (min-width: 701px) {
    justify-content: flex-start;
  }
}
.ai-tutor {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ai-header {
    color: #1d5796;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    text-align: center;
    line-height: 1.4;
    padding: 64px 16px 0;
    font-weight: bold;
    @media screen and (max-width: 700px) {
      font-size: 24px;
      padding: 32px 16px 0;
    }
  }
  .ai-flexbox {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    width: 100%;
    padding: 84px 0px 124px;
    @media screen and (max-width: 700px) {
      padding: 32px 0px 64px;
      gap: 0px;
    }
    & > div {
      flex: 1;
      display: flex;
      align-items: center;
      .inner-box {
        max-width: 400px;
        width: 100%;
      }
      .image-clip {
        position: relative;
        padding-top: 193.43%;
        overflow: hidden;
        border-radius: 10px;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
          max-width: 100%;
        }
        .screen {
          position: absolute;
          width: 77%;
          padding-top: 136.98%;
          overflow: hidden;
          left: 50%;
          top: 50%;
          transform: translate(-51%, -51%);
          z-index: 1;
          .display {
            position: absolute;
            width: 100%;
            height: 100%;
            max-width: 100%;
            left: 50%;
            top: 50%;
            object-fit: cover;
            object-position: top center;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
