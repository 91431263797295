@import "../common/variables";

.classmodel {
  position: relative;
  > div {
    padding: 100px 0;
  }
  .scroll01 {
    position: relative;
    text-align: center;
    .wrap {
      .titlebox {
        width: 100%;
        display: block;
        text-align: center;
        h3 {
          margin: 20px 0 50px;
        }
      }
      .slideupbox {
        position: relative;
        img {
          left: 0;
          position: absolute;
          top: 10%;
          z-index: -1;
        }
        iframe {
          width: 800px;
          height: 400px;
          border: 1px solid #ccc;
        }
      }
    }
  }
  .scroll02 {
    padding: 100px 0;
    background-color: #f6fbff;
    text-align: center;
    .notice {
      width: 1200px;
      margin: 0 auto 1%;
      text-align: center;
      i {
        font-size: 23px;
        color: #666;
        font-family: $mainFont;
        font-style: normal;
        line-height: 2;
        font-weight: lighter;
      }
    }
    .swiper-container {
      width: 100%;
      height: 100%;
      padding-bottom: 40px;
      position: relative;
      .swiper-wrapper {
        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background-color: #f6fbff;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: block;
            width: 45%;
            object-fit: contain;
          }
        }
      }
      .swiper-button-prev {
        left: 10%;
        &:after {
          font-size: 3rem;
        }
      }
      .swiper-button-next {
        right: 10%;
        &:after {
          font-size: 3rem;
        }
      }
      .swiper-pagination {
        left: 30%;
        .swiper-pagination-bullet {
          font-size: 0;
          // Adjust margin to move bullets closer to slides
          margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 3px);
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .classmodel .scroll02 .swiper-container .swiper-wrapper .swiper-slide img {
    width: 80%;
  }
  .swiper-button-prev {
    left: 5%; 
    &:after {
      font-size: 3rem;
    }
  }
  
  .swiper-button-next {
    right: 5%; 
    &:after {
      font-size: 3rem;
    }
  }
}

@media (max-width: 1200px) {
  .classmodel .scroll02 .notice {
    width: 100% !important;
    margin: 0 auto 1%;
  }
}

@media (max-width: 900px) {
  .classmodel .scroll01 .wrap .slideupbox iframe {
    width: 550px;
    height: 300px;
  }
  .swiper-button-prev {
    left: 4%;
  }
  .swiper-button-next {
    right: 4%;
  }
  .classmodel .scroll02 .notice i {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .classmodel .scroll01 {
    padding: 10% 0;
  }
  .classmodel .scroll01 .wrap .slideupbox iframe {
    width: 450px;
    height: 250px;
  }
  .classmodel .scroll02 {
    padding: 10% 0;
  }
  .classmodel .scroll02 .swiper-container .swiper-wrapper .swiper-slide img {
    width: 90%;
  }
  .swiper-button-prev {
    left: 1%;
  }
  .swiper-button-next {
    right: 1%;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 2rem;
  }
  .classmodel .scroll02 .notice i {
    font-size: 18px;
  }
}
@media (max-width: 500px) {
  .classmodel .scroll01 .wrap .slideupbox iframe {
    width: 320px;
    height: 180px;
  }
}

@media (max-width: 320px) {
  .classmodel .scroll01 .wrap .slideupbox iframe {
    width: 300px;
    height: 180px;
  }
  .classmodel .scroll02 .swiper-container {
    padding-bottom: 20px;
  }
  .swiper-button-prev {
    left: 0.5%;
  }
  .swiper-button-next {
    right: 0.5%;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 1rem;
  }
  .swiper-pagination-bullet {
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 5px)
    );
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 5px)
    );
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 2px);
  }

  .classmodel .scroll02 .notice i {
    font-size: 14px;
  }
}

#scroll01 {
  .slideupbox {
    position: relative;
    top: 100px;
    opacity: 0;
    transition: opacity 0.3s, top 0.3s;
  }
}

#scroll01.on {
  .slideupbox {
    top: 0px;
    opacity: 1;
  }
}
